import React, { Component } from 'react'
import Userinfo from '../../partial/Userinfo'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Status extends Component {
    listReport = () => {
        return this.props.main.report && this.props.main.report.map((el,i) => {
            return (
                <tr key={i}>
                    <td>{el.cheater_nickname}</td>
                    <td>{el.status === 0 ? "Ongoing" : el.status === 1 ? "Accepted" : "Rejected"}</td>
                    <td className="keterangan">{el.keterangan}</td>
                </tr>
            )
        })
    }
    render() {
        console.log(this.props)
        if (this.props.main.user) {
            return (
                <div id="Status">
                    <Userinfo title={"Reported Player Status"} {...this.props} />
                    <div className="close">
                        <img onClick={() => this.props.history.push('/main')} src={require('assets/img/Button_Close.png')} alt=""/>
                    </div>
                    <div className="tableStatus">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nickname</th>
                                    <th>Status</th>
                                    <th className="keterangan" >Keterangan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.listReport()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="Error">Kamu harus Login Terlebih Dahulu<br/>
                    <button onClick={() => this.props.history.push('/login')} className="btn btn__submit">Login disini</button>
                </div>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        main : state.main
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions : bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);