const main_state = {
	user : false,
	loader: false,
	report: false
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "GET_LOGIN":
			return { ...state, user: action.data.user };
		case "GET_REPORT":
			return { ...state, report : action.data};
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		default:
			return state;
	}
};

export default main;