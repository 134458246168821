import axios from 'axios';
import moment from 'moment';
import {put_data_modal, toggle_modal} from './modal';

const keyOfInput = {
    category : "Kategori",
    cheater_nickname : "Cheater Nickname",
    date : "Tanggal Kejadian",
    time : "Jam",
    second : "Menit",
    url : "Link",
    reported_nickname : "Nickname ini telah dilaporkan orang lain dan akan dihukum apabila bersalah",
    banned_nickname : "Nickname ini telah dibanned."
}

export const get_login = (data) => ({
    type: 'GET_LOGIN',
    data : data
})

export const get_report = (data) => ({
    type: 'GET_REPORT',
    data : data
})


export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data : data
})

export const login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get('api/info_user/')
        .then((resp) => {
            console.log(resp)
            dispatch(get_login(resp.data));
            dispatch(listReport());
        })
        .catch((err) => {
            console.log(err)
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const listReport = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get('api/report/')
        .then((resp) => {
            console.log(resp);
            dispatch(get_report(resp.data));
        })
        .catch((err) => {
            console.log(err)
        })
    }
}

export const doReport = (data) => {
    let formData = new FormData();
    const dt = moment(data.date).add(data.time, 'hours').add(data.second, 'minutes')
    let datetime = moment(dt).format('YYYY-MM-DD HH:mm')
    // d(mont);
    // const datetime = moment(dt).format("YYYY-MM-DD HH:mm");
    function isUrlValid(userInput) {
        var pattern = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i);
        return pattern.test(userInput);
    }
    if (!isUrlValid(data.url) && data.url.length > 0) {
        data['url'] = 'invalid'
    }
    formData.append('kategori', data.category);
    formData.append('cheater_nickname', data.cheater_nickname);
    formData.append('datetime', datetime);
    formData.append('url', data.url);
    return dispatch => {
        let message = [];
        for (let key in data) {
            if (!data[key]) {
                message.push(keyOfInput[key] + " harus diisi");
            }
            if (data[key] === 'invalid') {
                message.push(keyOfInput[key] + " tidak valid");
            }
        }
        dispatch(toggle_loader(true));
        if (message.length > 0) {
            dispatch(put_data_modal('message', message));
            dispatch(toggle_modal('Warn', true));
        } else {
            axios.post('api/report/', formData)
            .then((resp) => {
                dispatch(put_data_modal('message', ['Report telah berhasil.']));
                dispatch(toggle_modal('Success', true));
                dispatch(listReport());
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data.msg) {
                        dispatch(put_data_modal('message', [keyOfInput[err.response.data.msg] || err.response.data.msg]));
                    } else {
                        dispatch(put_data_modal('message', ['Terjadi Kesalahan']));
                    }
                }
                dispatch(toggle_modal('Warn', true));
            })
            .finally(() => {
                dispatch(toggle_loader(false))
            })
        }
    }
}