import React, { Component } from 'react';
import axios from 'axios';
import {getUrlParam} from '../../helper/index';

export default class Auth extends Component {
    componentDidMount() {
        console.log('auth come');
        const token  = getUrlParam('token');
        if (token) {
            window.localStorage.setItem('token', token);
            axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
            this.props.history.push({
                pathname : '/main',
                state : {auth : true}
            });
        } else {
            this.props.history.push({
                pathname : '/main',
                state : {auth : false}
            });
        }
       
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
