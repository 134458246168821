import React from "react";

class Footer extends React.Component {
	render() {
		return (
			<div className="footer">
				<div className="logo-cont">
					<img src={require('assets/img/footer/Logo_Garena.png')} alt="" className="img-fluid"/>
					<img src={require('assets/img/footer/Logo_Activition.png')} alt="" className="img-fluid"/>
					{/* <img src={require('assets/img/footer/Logo_Tencent.png')} alt="" className="img-fluid"/> */}
					<img src={require('assets/img/footer/Logo_Timi.png')} alt="" className="img-fluid"/>
				</div>
				{/* <ul>
					<li>
						<img src={require('assets/img/footer/Logo_Garena.png')} alt="" className="img-fluid"/>
					</li>
					<li>
						<img src={require('assets/img/footer/Logo_Activition.png')} alt="" className="img-fluid"/>
					</li>
					<li>
						<img src={require('assets/img/footer/Logo_Timi.png')} alt="" className="img-fluid"/>
					</li>
				</ul> */}
				<div className="text">
					<p>
						Copyright © Garena Online. Copyright © Tencent. All rights reserved.
					</p>
					<p>© 2019 Activision Publishing, Inc. ACTIVISION and CALL OF DUTY are trademarks of Activision Publishing, Inc.Trademarks belong to their respective owners. All rights reserved.</p>
				</div>
			</div>
		);
	}
}

export default Footer;
