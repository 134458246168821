import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Userinfo from '../../partial/Userinfo';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';


class Home extends Component {
    state = {
        auth : false
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState(() => {
                return {
                    auth : this.props.location.state.auth
                }
            }); 
        }
    }
    render() {
       
        if (this.state.auth || window.localStorage.getItem('token')) {
            return (
                <div id="Home">
                    <Userinfo title="Homepage" {...this.props} />
                    <div className="group-button">
                        <Link to="/status">
                            <img alt="" src={require('assets/img/Button_Players-Status.png')} />
                        </Link>
                        <Link to="/report">
                            <img alt="" src={require('assets/img/Button_Report-Player.png')} />
                        </Link>
                        {/* eslint-disable */}
                        <a onClick={() => this.props.modalActions.toggle_modal('Rules', true)}>
                            <img alt="" src={require('assets/img/btn-rules.png')} />
                        </a>
                    </div>
                </div>
            );
        } else {
            this.props.history.push('/login');
            return (
                <div></div>
            )
        }
       
    }
}

function mapStateToProps(state) {
    return {
        main : state.main
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mainActions : bindActionCreators(mainActions, dispatch),
        modalActions : bindActionCreators(modalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);