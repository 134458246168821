const modal_state = {
	is_open: false,
	is_type: false,
	menu: false,
	collapse: true,
	collapse_type: 'guide',
	video_link: []
}

const modal = (state = modal_state, action) => {
    switch (action.type) {
		case "TOGGLE_MODAL":
			return { 
				...state,
				is_open: action.data,
				is_type: action.modal
			};
		case "TOGGLE_MENU":
			return {
				...state,
				menu: action.data
			}
		case "TOGGLE_COLLAPSE":
			return {
				...state,
				collapse : (action.data !== state.collapse_type) ? true : !state.collapse,
				collapse_type: action.data
			}
		case "PUT_DATA_MODAL":
			return {
				...state,
				[action.key] : action.data
			}
		default:
			return state;
	}
};

export default modal;
