import React from "react";
import { Link } from 'react-router-dom';

class Navbar extends React.Component {

	togglecollapse = (type) =>{
		this.props.modalActions.toggle_collapse(type);
	}

	home = () =>{
		this.props.modalActions.toggle_menu(false)
	}
	
	render() {
		const {menu,collapse,collapse_type} = this.props.modal;
		const {modalActions} = this.props;

		return (
			<div className="nav">
				<div className="cont_nav">
					<div className="logo">
						<img src={require('assets/img/Logo_CODM_.png')} alt="" className="img-fluid"/>
					</div>
					<ul>
						<li className="dropdown">
							{/* eslint-disable */}
							<a>Guide</a>
							<ul className="list">
								<li><a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch">Mode</a></li>
								<li><a href="https://main.codm.garena.co.id/guide/feature/scorestreak">Fitur Unik</a></li>
								<li><a href="https://main.codm.garena.co.id/guide/character">Karakter</a></li>
								<li><a href="https://main.codm.garena.co.id/guide/maps">Maps</a></li>
								<li><a href="https://main.codm.garena.co.id/guide/weapons/assault_rifle">Senjata</a></li>
							</ul>
						</li>
						<li className="dropdown">
							<a>Berita</a>
							<ul className="list">
								<li><a href="https://main.codm.garena.co.id/news/all">Semua</a></li>
								<li><a href="https://main.codm.garena.co.id/news/update">Update</a></li>
								<li><a href="https://main.codm.garena.co.id/news/event">Event</a></li>
							</ul>
						</li>
						<li><a href="https://support.garena.co.id" target="_blank">Support</a></li>
						<li><a href="https://fairplay.codm.garena.co.id">Fairplay</a></li>
					</ul>
				</div>
				<div className="navbar_mobile">
					<div className="nav-cont">
						<div className={`hamburger hamburger--spin ${(menu) ? `is-active` : ``}`} onClick={()=>modalActions.toggle_menu(!menu)}>
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</div>
						<img src={require('assets/img/Logo_CODM_.png')} className="logo" alt="" onClick={ () => this.home() }/>
					</div>
					<div className={`overlay-menu ${(menu) ? `active` : ``}`}>
						<div className={`menu ${(menu) ? `active` : ``}`}>
							<ul>
								<li><a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch" onClick={()=>modalActions.toggle_menu(false)}>Beranda</a></li>
								<li className={`collapse ${(collapse && collapse_type === 'guide') ? `open`:``}`}>
									<a onClick={()=>this.togglecollapse('guide')}>Guide</a>
									<div className="coll-content">
										<ul className="coll-menu">
											<li><a href="https://main.codm.garena.co.id/guide/mode/team_deathmatch" onClick={()=>modalActions.toggle_menu(false)}>Mode</a></li>
											<li><a href="https://main.codm.garena.co.id/guide/feature/scorestreak" onClick={()=>modalActions.toggle_menu(false)}>Fitur Unik</a></li>
											<li><a href="https://main.codm.garena.co.id/guide/character" onClick={()=>modalActions.toggle_menu(false)}>Karakter</a></li>
											<li><a href="https://main.codm.garena.co.id/guide/maps" onClick={()=>modalActions.toggle_menu(false)}>Maps</a></li>
											<li><a href="https://main.codm.garena.co.id/guide/weapons" onClick={()=>modalActions.toggle_menu(false)}>Senjata</a></li>
										</ul>
									</div>
								</li>
								<li><Link to="/news/all" onClick={()=>modalActions.toggle_menu(false)}>Berita</Link></li>
								<li><a href="https://support.garena.co.id" target="_blank" onClick={()=>modalActions.toggle_menu(false)}>Support</a></li>
								<li className={`collapse ${(collapse && collapse_type === 'sosmed') ? `open`:``}`}>
									<a onClick={()=>this.togglecollapse('sosmed')}>Social Media</a>
									<div className="coll-content">
										<ul className="coll-menu">
											<li><a href="" onClick={()=>modalActions.toggle_menu(false)}>Facebook</a></li>
											<li><a href="" onClick={()=>modalActions.toggle_menu(false)}>Instagram</a></li>
											<li><a href="" onClick={()=>modalActions.toggle_menu(false)}>Youtube</a></li>
										</ul>
									</div>
								</li>
								<li><a href="https://fairplay.codm.garena.co.id">Fairplay</a></li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default Navbar;
