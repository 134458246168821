import React, { Component } from "react";
import Userinfo from "../../partial/Userinfo";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";

const $ = window.$;
class Report extends Component {
	state = {
		data: {
			category: false,
			cheater_nickname: false,
			date: false,
			time: false,
			second: false,
			url: false
		}
	};
	listCategory = () => {
		return (
			this.props.main.user.kategori &&
			this.props.main.user.kategori.map((el, i) => {
				return (
					<option key={i} value={el}>
						{el}
					</option>
				);
			})
		);
	};
	collectInput = (e, data) => {
		e.persist();
		switch (data) {
			case "Category":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							category: e.target.value
						}
					};
				});
				break;
			case "Nickname":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							cheater_nickname: e.target.value
						}
					};
				});
				break;
			case "Date":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							date: e.target.value
						}
					};
				});
				break;
			case "Time":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							time: e.target.value
						}
					};
				});
				break;
			case "Second":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							second: e.target.value
						}
					};
				});
				break;
			case "Url":
				this.setState((prev) => {
					return {
						...prev,
						data: {
							...prev.data,
							url: e.target.value
						}
					};
				});
				break;
			default:
				break;
		}
	};
	componentDidMount() {
		if ($("#datePicker").prop("type") !== "date") {
			$("#datePicker").datepicker();
		}
	}
	render() {
		if (this.props.main.user) {
			return (
				<div id="Report">
					<Userinfo {...this.props} title="Report Player" />
					<div className="close">
						<img
							onClick={() => this.props.history.push("/main")}
							src={require("assets/img/Button_Close.png")}
							alt=""
						/>
					</div>
					<div className="form">
						<div className="label-input">
							<span>Kategori Cheater</span>
							<select onChange={(e) => this.collectInput(e, "Category")} required>
								<option disabled selected value="">
									-Pilih Kategori Cheater-
								</option>
								{this.listCategory()}
							</select>
						</div>
						<div className="label-input">
							<span>Cheater Nickname/UID</span>
							<input
								onChange={(e) => this.collectInput(e, "Nickname")}
								placeholder="Cheater Nickname/UID"
								type="text"
							/>
						</div>
						<div className="label-group">
							<div className="label-input date">
								<span>Tanggal & Jam Kejadian</span>
								<input
									data-date=""
									data-date-format="dd/mm/yyyy"
									onChange={(e) => this.collectInput(e, "Date")}
									type="date"
									id="datePicker"
								/>
							</div>
							<div className="label-input time">
								<span>Jam</span>
								<input onChange={(e) => this.collectInput(e, "Time")} type="number" placeholder="00" />
							</div>
							<div className="label-input second">
								<span>Menit</span>
								<input
									onChange={(e) => this.collectInput(e, "Second")}
									type="number"
									placeholder="00"
								/>
							</div>
						</div>
						<div className="label-input">
							<span style={{ marginTop: "1vw", marginBottom: 0 }}>Video/Screenshot pendukung</span>
							<span className="info">
								Kamu bisa mengupload gambar di <a href="https://prnt.sc/">https://prnt.sc/</a> maupun
								video di <a href="https://mediafire.com/">https://mediafire.com/</a>atau{" "}
								<a href="https://drive.google.com">https://drive.google.com</a>
							</span>
							<input
								onChange={(e) => this.collectInput(e, "Url")}
								type="text"
								placeholder="Url gambar atau video"
							/>
						</div>
						<button
							onClick={() => this.props.mainActions.doReport(this.state.data)}
							className="btn btn__submit"
						>
							Submit
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div id="Error">
					Kamu harus Login Terlebih Dahulu
					<br />
					<button onClick={() => this.props.history.push("/login")} className="btn btn__submit">
						Login disini
					</button>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		main: state.main
	};
}

function mapDispatchToProps(dispatch) {
	return {
		mainActions: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
