import React, { Component } from 'react'

export default class Login extends Component {
    render() {
        return (
            <div id="Login">
                {/* <img onClick={() => {window.location.href= process.env.REACT_APP_FB_URL}} alt="" src={require('assets/img/Login_FAacebook.jpg')} /> */}
                {/* <img onClick={() => {window.location.href= "https://www.facebook.com/dialog/oauth?client_id=341893326515823&redirect_uri=https%3A%2F%2Fauth.garena.com%2Foauth%2Flogin%3Fall_platforms%3D1%26response_type%3Dtoken%26locale%3Did-ID%26client_id%3D100082%26redirect_uri%3Dhttps%3A%2F%2Fauth.codm.garena.com%2Fauth%2Fauth%2Fcallback_n%3Fsite%3Dhttp%3A%2F%2F10.10.118.109%3A8000%2Foauth%2Fcallback&response_type=token&scope=public_profile%2Cemail%2Cuser_friends"}} alt="" src={require('assets/img/Login_FAacebook.jpg')} />  */}
                <img onClick={() => {window.location.href = process.env.REACT_APP_API_ENDPOINT+'oauth/login'}} alt="" src={require('assets/img/Login_Garena.jpg')} />
            </div>
        )
    }
}
