import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// Component
import ViewportRestrict from 'component/viewport';
import Loader from 'component/loader';
// import NavHamburger from 'component/navbar';

// Pages
import Home from 'pages/Home';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';
import Navbar from '../partial/Navbar';
import Footer from '../partial/Footer';
import Login from '../pages/Login';
import Status from '../pages/Status';
import Report from '../pages/Report';
import Auth from '../pages/Auth';
import Popup from '../partial/Popup';
import { getUrlParam } from '../helper';

const NoMatch = () => (
    <div>404 Not Found</div>
);

const ERROR = {
	3 : 'Gagal login, akun tidak bermain Call of Duty® Mobile – Garena',
	4 : 'Gagal login, akun tidak bermain Call of Duty® Mobile – Garena'
}

class Main extends Component {
	
	componentDidMount() {
		const err = getUrlParam('err');
		
		if (err) {
			console.log(err);
			this.props.modalActions.put_data_modal('message', [ERROR[err]]);
			this.props.modalActions.toggle_modal('Warn', true);
		} else {
			this.props.mainActions.login();
		}
	}

    render() {
        return (
            <Router basename={process.env.REACT_APP_BASENAME}>
				<div id="Main" className="main-panel wrapper">
					<ViewportRestrict display={false} type="landscape"/>
					<Loader/>
					<Popup />
					<Navbar {...this.props} />
					<div className="content-container">
						<ErrorBoundary>
							<Switch>
								<Route exact path="/main" component={Home} />
								<Route exact path="/login" component={Login} />
								<Route exact path="/status" component={Status} />
								<Route exact path="/report" component={Report} />
								<Route exact path="/auth" component={Auth} />
								<Redirect exact from="/" to={{
									pathname: "/main",
									search : window.location.search
								}} />
								{/* <Route exact path="/status" component={} */}
								<Route component={NoMatch} />
							</Switch>
						</ErrorBoundary>
					</div>
					<Footer />
				</div>
			</Router>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main, modal: state.modal }
}

function mapDispatchToProps(dispatch) {
	return {
		mainActions: bindActionCreators(mainActions, dispatch),
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);