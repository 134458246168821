export const toggle_modal = (modal, data) => ({
    type: 'TOGGLE_MODAL',
    modal,
    data
})

export const toggle_menu = (data)=>({
	type: 'TOGGLE_MENU',
	data
})

export const toggle_collapse = (data)=>({
	type: 'TOGGLE_COLLAPSE',
	data
})

export const put_data_modal = (key, data) => ({
    type: "PUT_DATA_MODAL",
    key,
    data
})
