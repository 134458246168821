import React, { Component } from 'react'

export default class Userinfo extends Component {
    handleLogout = () => {
        const token = window.localStorage.getItem('token');
        window.localStorage.removeItem('token');
        window.location.href = process.env.REACT_APP_API_ENDPOINT + "oauth/logout/?token=" + token;
    }
    render() {
        return (
            <div className="nickname">
                <span>Hi, {decodeURIComponent(this.props.main.user.nickname)} </span>
                <div className="tabCurrent">
                    <span>{this.props.title}</span>
                    <button onClick={() => {
                        this.handleLogout()
                    }} className="btn btn__logout">Logout</button>
                </div>
            </div>
        )
    }
}
