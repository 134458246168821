import React, { Component } from 'react'
import Modal from 'react-responsive-modal';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';

const Success = ({modalActions, modal}) => {
    return (
        <Modal
            open = {modal.is_open}
            onClose = {() => {modalActions.toggle_modal('Success', false); window.location.href = "/status"}}
            classNames={{modal : "modal modal__success", closeButton: "closeButton"}}
            showCloseIcon={false}
            focusTrapped={false}
            center
            >
                <div>
                    {
                        modal.message ? modal.message.map((el, i) => {
                            return (
                                <p key={i}>{el}</p>
                            )
                        })
                        : <p>Report behasil dilakukan.</p>
                    }
                <button className="btn btn__close" onClick={() => {modalActions.toggle_modal('Success', false); window.location.href = "/status"}}>
                    Close
                </button>
                </div>
            </Modal>
    )
}

const Warn = ({modalActions, modal}) => {
    return (
        <Modal
            open = {modal.is_open}
            onClose = { () => modalActions.toggle_modal('Warn', false)}
            classNames={{modal : "modal modal__success", closeButton: "closeButton"}}
            center
            showCloseIcon={false}
            focusTrapped={false}
            >
                <div>
                    {
                        modal.message ? modal.message.map((el, i) => {
                            return (
                                <p key={i}>{el}</p>
                            )
                        })
                        : <p>Terjadi kesalahan, Silahkan coba lagi.</p>
                    }
                    <button className="btn btn__close" onClick={() => modalActions.toggle_modal('Warn', false)}>
                        Close
                    </button>
                </div>
                
            </Modal>
    )
}

const Rules = ({modalActions, modal}) => {
    const listOfRules = [
        'Pemain yang menggunakan program illegal akan kami banned permanent tanpa pengampunan unban',
        'Pemain yang menggunakan kata-kata kasar maupun non-alphanumeric dalam nickname akan diganti secara paksa',
        'Apabila pemain terindikasi menggunakan jasa GB dengan program illegal, maka pengguna program illegal beserta pemain tersebut akan di banned secara permanen',
        'Pemain yang secara sengaja menggunakan kata-kata kasar/SARA akan di ban kemampuan untuk chatting sesuai dengan tingkat keparahan kata-kata yang digunakan'
    ]
    return (
        <Modal
            open = {modal.is_open}
            onClose = { () => modalActions.toggle_modal('WRulesarn', false)}
            classNames={{modal : "modal modal__rules", closeButton: "closeButton"}}
            center
            showCloseIcon={false}
            focusTrapped={false}
            >
                <div>
                    <ol>
                        {
                            listOfRules.map((el ,i) => {
                                return (
                                    <li key={i}>{el}</li>
                                )
                            })
                        }
                    </ol>
                    <button className="btn btn__close" onClick={() => modalActions.toggle_modal('Rules', false)}>
                        Close
                    </button>
                </div>
                
            </Modal>
    )
}


class Popup extends Component {
    render() {
        switch(this.props.modal.is_type) {
            case 'Error' :
                return (
                    <div>
                        
                    </div>
                );
            case 'Success' :
                return (
                    <Success {...this.props} />
                );
            case 'Warn' :
                return (
                    <Warn {...this.props} />
                )
            case 'Rules':
                return (
                    <Rules {...this.props} />
                )
            default :
                return (
                    <div>
                        
                    </div>
                )
        }
        
    }
}

function mapStateToProps(state) {
	return { main: state.main, modal: state.modal }
}

function mapDispatchToProps(dispatch) {
	return {
		mainActions: bindActionCreators(mainActions, dispatch),
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);